.car-details-slider{
    width: 100%;
    /* height : 40vh; */
}
.car-details-slider .swiper-button-prev ,
.car-details-slider .swiper-button-next {
    background-color: var(--primary) !important;
    top : 50% !important;
    width: 50px !important;
    height : 50px !important;
    border-radius: 50%;
}

.swiper-button-next::after , .swiper-button-prev::after {
    font-size: 1.2rem !important;
    color : white !important;
    font-weight: bold !important;
}

.swiper-pagination-bullet {
    width: 14px !important;
    height : 14px !important; 
}

@media (max-width : 800px) {
    .car-details-slider .swiper-button-prev ,
    .car-details-slider .swiper-button-next {
        width: 30px !important;
        height : 30px !important;
    }
    .swiper-button-next::after , .swiper-button-prev::after {
        font-size: 14px !important;
    } 
    .swiper-button-next::after{
        margin-left : 3px !important;
    }
    .swiper-button-prev::after {
        margin-right : 3px !important;
    }
    .swiper-pagination-bullet {
        width: 10px !important;
        height : 10px !important; 
    }
}
@media (max-width: 600px) {
    
}